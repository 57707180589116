(function() {
    jQuery(function ($) {
        $('.main-nav ul').hide();
        $('.menu-toggle').on('click', function () {
            $('.main-nav .pages').slideToggle('fast');
        });

        $('.menu-item-has-children').on('click', function () {
            if ($(document).width() < 1026) {
                $(this).find('.sub-menu').slideToggle('fast');
                $(this).toggleClass('open');
            }
        });
    });
})($)